import { useState, useEffect, useRef } from 'react';
import MedTxLogo from '../../assets/images/medtx-logo.png';
import './PatientEncounters.scss';
import axios from 'axios';
import { io } from 'socket.io-client';
import CloseIcon from '../../assets/icons/close-line-icon.svg';
import TrashIcon from '../../assets/icons/recycle-bin-line-icon.svg';
import DownIcon from '../../assets/icons/line-angle-down-icon.svg';
import CopyIcon from '../../assets/icons/copy-line-icon.svg';

const baseApi = window.location.host.includes('localhost')
  ? 'http://localhost:5998'
  : 'https://api.alphie-ai.com';

const socketPath = window.location.host.includes('localhost')
  ? 'ws://localhost:5999'
  : 'wss://api.alphie-ai.com';

const PatientEncounters = (props) => {
  const { token, email, socketRef } = props;
  const columnLabels = ["Created", "Last Modified Time", "Transcript", "Chart", "Notes", "Comments", "Final Chart"];

  const [records, setRecords] = useState([]);
  const [activeRecord, setActiveRecord] = useState({
    field: "",
    record: {}
  });
  const [connected, setConnected] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showSideField, setShowSideField] = useState(false);
  const [activeSideField, setActiveSideField] = useState(''); // copy, copy all, delete, delete all
  const [recordsFetched, setRecordsFetched] = useState('');

    // for the header cell width matching
  // https://stackoverflow.com/a/9541579
  const checkElementOverflow = (element) => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

  const checkTableBodyOverflow = () => {
      const tableHeader = document.querySelector('.App__PatientEncounters-table-header');
      const tableBody = document.querySelector('.App__PatientEncounters-table-body');

      if (checkElementOverflow(tableBody)) {
        tableHeader.classList.add('body-overflow');
      } else {
        tableHeader.classList.remove('body-overflow');
      }
  };

  const getUserData = () => {
    axios.post(
      `${baseApi}/airtable-get-user-data`,
      {
        token,
        email
      }
    )
    .then(response => {
      if (response.data?.records.length) {
        let sorted = {};

        // sort by created date
        sorted = response.data.records.sort((a, b) => {
          if (a.fields.Created < b.fields.Created) return -1;
          if (a.fields.Created > b.fields.Created) return 1;
          return 0;
        });

        setRecords(sorted);
        setRecordsFetched(true);
      }
    })
    .catch((error) => {
      console.log('error ' + error);
    });
  };

  const updateRecord = () => {
    const recordId = activeRecord.record.id;
    const field = activeRecord.field;
    const fieldData = activeRecord.record[field];
    
    setProcessing(true);

    axios.post(
      `${baseApi}/airtable-update-user-data`,
      {
        token,
        email,
        recordId,
        field,
        fieldData
      }
    )
    .then(response => {
      if (response?.data?.id) {
        setRecords(records => {
          const recordsUpdated = records;
          const updateRecordArrId = records.findIndex(record => record.id === recordId);

          records[updateRecordArrId] = {
            createdTime: records[updateRecordArrId].createdTime,
            fields: {
              ...records[updateRecordArrId].fields,
              [field]: fieldData
            },
            id: records[updateRecordArrId].id
          };

          return recordsUpdated;
        });
        setActiveRecord({
          field: "",
          record: {}
        });
      } else {
        alert("Failed to update data");
      }
    })
    .catch((error) => {
      console.log('error ' + error);
    })
    .finally(() => {
      setProcessing(false);
    });
  }

  const deleteRecord = (recordId) => {
    setProcessing(true);

    axios.post(
      `${baseApi}/airtable-delete-record`,
      {
        token,
        email,
        recordId
      }
    )
    .then(response => {
      if (response.status === 200 && response?.data.deleted) {
        alert('Deleted');
        getUserData();
      } else {
        alert("Failed to delete row");
      }
    })
    .catch((error) => {
      console.log('error ' + error);
    })
    .finally(() => {
      setProcessing(false);
    });
  }

  const toggleSideFieldOptions = () => {
    setShowSideField(!showSideField);
  };

  const sideField = (which = '', recordId = '', counter = 0) => {
    if (which === 'header') {
      return <div className="App__PatientEncounters-table-header-delete">
        <button className="App__PatientEncounters-table-header-delete-arrow" type="button" onClick={() => toggleSideFieldOptions()}>
          <img src={DownIcon} alt="down icon"/>  
        </button>
        {showSideField && <div className="App__PatientEncounters-table-header-delete-options">
          <button type="button" onClick={() => setActiveSideField('copy')}>Copy</button>
          <button type="button">Copy All</button>
          <button type="button" onClick={() => setActiveSideField('delete')}>Delete</button>
          <button type="button">Delete All</button>
        </div>}
      </div>;
    }
    
    if (which === 'body') {
      return <div className="App__PatientEncounters-table-body-delete" title="delete">
        {activeSideField === "delete" && <button type="button" onClick={() => {
          if (window.confirm("Delete row?")) {
            deleteRecord(recordId)
          }
        }}>
          <img src={TrashIcon} alt="delete icon"/>
        </button>}
        {activeSideField === "copy" && <button type="button" onClick={() => {}}>
          <img src={CopyIcon} alt="delete icon"/>
        </button>}
        {(!activeSideField) && <div className="App__PatientEncounters-table-body-counter">
          {counter}
        </div>}
      </div>;
    }
  }

  const renderTableHeader = () => (
    columnLabels.map((label, index) => (
      <div
        key={index}
        className={`App__PatientEncounters-table-header-cell ${label.split(' ').join('-').toLowerCase()}`}
      >
        {label}
      </div>
    ))
  );

  const formatText = (text, label) => {
    if (text) {
      if (label.includes('Chart') || label === 'Transcript') {
        return text.substring(0, 50);
      }
    }

    return text;
  }

  const renderTableRow = (record) => (
    columnLabels.map((label, index) => (
      <div
        key={index}
        title={record.fields[label]}
        className={`App__PatientEncounters-table-body-cell ${label.split(' ').join('-').toLowerCase()} ${['Created', 'Last Modified Time'].includes(label) ? 'center' : ''}`}
        onClick={() => {
          if (!['Created', 'Last Modified Time'].includes(label)) {
            setActiveRecord({
            field: label,
              record
            });
          }
      }}
      >
        {formatText(record.fields[label], label)}
      </div>
    ))
  );

  const renderTable = (records) => {
    return (
      <div className="App__PatientEncounters-table">
        <div className="App__PatientEncounters-table-header">
          {sideField('header')}
          {renderTableHeader()}
        </div>
        <div className="App__PatientEncounters-table-body">
          {records.map((record, index) => (
            <div key={index} className="App__PatientEncounters-table-row">
              {sideField('body', record.id, index + 1)}
              {renderTableRow(record)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  const renderModal = () => {
    if (activeRecord) {
      return <div className='App__PatientEncounters-modal-container'>
        <div className="App__PatientEncounters-modal">
          <button
            type="button"
            className="App__PatientEncounters-modal-close"
            title="cancel"
            onClick={() => setActiveRecord({
              field: '',
              record: {}
            })}
          >
            <img src={CloseIcon} alt="close icon"/>
          </button>
          <h3>{activeRecord.field}</h3>
          <textarea
            type="text"
            placeholder=""
            onChange={(e) => {
              setActiveRecord({
                field: activeRecord.field,
                record: {
                  ...activeRecord.record,
                  [activeRecord.field]: e.target.value,
                }
              });
            }}
            defaultValue={activeRecord.record.fields[activeRecord.field]}
          />
          <button
            className="App__PatientEncounters-modal-save"
            type="button"
            onClick={() => updateRecord()}
            disabled={processing ? true : false}
          >
            { processing ? 'Saving...' : 'Save' }
          </button>
        </div>
      </div>
    }
  }

  const socketPing = (ws) => {
    setTimeout(() => {
      ws.emit('webPing', {
        email
      });
    }, 3000);
  }

  const connectSocket = () => {
    if (connected) return;

    socketRef.current = io(socketPath, {
      path: "/ws"
    });

    const ws = socketRef.current;

    ws.emit('webApp', {
      email,
      token
    });

    ws.on('connected', () => {
      if (!connected) {
        setConnected(true);
      }
      socketPing(ws);
    });

    ws.on('newData', () => {
      getUserData();
    });

    ws.on('pong', () => {
      if (!connected) {
        setConnected(true);
      }

      socketPing(ws);
    });

    ws.on('disconnect', () => {
      socketRef.current = null;
      setConnected(false);

      setTimeout(() => {
        connectSocket();
      }, 1000);
    });
  }

  const offClick = () => {
    if (showSideField) {
      toggleSideFieldOptions();
    }
  }

  useEffect(() => {
    if (records.length) {
      checkTableBodyOverflow();
    }
  }, [records]);

  useEffect(() => {
    connectSocket();
    getUserData();
  }, []);

  return (
    <div className="App__PatientEncounters" onClick={() => offClick()}>
      <div className="App__PatientEncounters-header">
        <div className="App__PatientEncounters-header-logo-group">
          <img src={MedTxLogo} alt="MedTx logo"/>
          <h2>MedTx</h2>
        </div>
        <div className="App__PatientEncounters-header-title-status">
          <h1>Patient Encounters</h1>
          <div className={`App__PatientEncounters-header-status ${connected ? 'connected' : ''}`}></div>
        </div>
      </div>
      <div className="App__PatientEncounters-body">
        {records.length > 0 && renderTable(records)}
        {activeRecord?.field && renderModal()}
        {!(records.length > 0) && <div className="App__PatientEncounters-body-waiting">
          {recordsFetched ? "No records" : "Fetching data..."}
        </div>}
      </div>
    </div>
  );
}

export default PatientEncounters;